import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/halloween-2007-poster.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 2007 slasher movie remake, Halloween"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 22</h1>
                    <h2>Halloween (2007)</h2>
                    <h3>October 16, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Rob Zombie // <b>Starring:</b> Sheri Moon Zombie &amp; Tyler Mane</p>
                        <div className="player">
                        <iframe src="https://podcasters.spotify.com/pod/show/bring-me-the-axe/embed/episodes/22-Halloween-2007-e2akt8h" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe! horror podcast episode 22, Halloween 2007"></iframe>
                        </div>
                        <p><Link to="/transcripts/halloween-2007">Read the episode transcript</Link></p>
                        <p>This week, Bryan and Dave are joined by The Horror Chick, Heather Wixson to get grimy and talk about Rob Zombie's arch-2000's horror movie remake, Halloween. It reimagines Michael Myers, the silent destroyer, once a little boy with a family of mean dirtbag shitheads and a loving, overprotective mother and Sam Loomis as a selfish, fame-chasing jerk.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=Z5oFtbWvJRY" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=Z5oFtbWvJRY</OutboundLink></p>
                        <p>Monsters, Makeup and Effects: <OutboundLink href="https://amzn.to/45GfJt6" target="_blank" rel="noreferrer">https://amzn.to/45GfJt6</OutboundLink></p>
                        <p>Monsters, Makeup and Effects 2: <OutboundLink href="https://amzn.to/4050Zmb" target="_blank" rel="noreferrer">https://amzn.to/4050Zmb</OutboundLink></p>
                        <p>Heather Wixson on Twitter: <OutboundLink href="https://twitter.com/thehorrorchick" target="_blank" rel="noreferrer">https://twitter.com/thehorrorchick</OutboundLink></p>
                        <p>Heather Wixson on Instagram: <OutboundLink href="https://www.instagram.com/thehorrorchick/" target="_blank" rel="noreferrer">https://www.instagram.com/thehorrorchick/</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/hallowee-1978">Next Episode</Link></li>
                        <li><Link to="/episodes/friday-the-13th">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)